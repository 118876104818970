import { render, staticRenderFns } from "./face-element.vue?vue&type=template&id=7a15f3de&scoped=true"
import script from "./face-element.vue?vue&type=script&lang=js"
export * from "./face-element.vue?vue&type=script&lang=js"
import style0 from "./face-element.vue?vue&type=style&index=0&id=7a15f3de&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a15f3de",
  null
  
)

export default component.exports